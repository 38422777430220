import React from 'react';
import { Text } from '@goodfynd/react-web.lib.ui';
import { StyledFlex } from '@goodfynd/react-web.styles';
import { format, getTimeDisplay } from '@goodfynd/react-web.utils.date-util';
import { convertDistance, getPreciseDistance } from 'geolib';

import config from '../../../config';
import strings from '../../../config/strings';
import { local as storageLocal } from '../../../utils/storage-util';

import {
  StyledCardContainer,
  StyledLocation,
  StyledNumber,
  StyledVendorImage,
} from './styles';
import type { ScheduleMapCardProps } from './types';

export function ScheduleMapPinHover({
  item,
  number = 0,
}: ScheduleMapCardProps) {
  const userLocation = storageLocal.get<ItemLocation>(
    config.storageKeys.userLocation
  );

  return (
    <StyledCardContainer
      css={{
        padding: 0,
      }}
    >
      <div className="content" style={{ padding: 0 }}>
        <div>
          <StyledFlex
            css={{
              alignItems: 'flex-start',
              flexWrap: 'nowrap',
              gap: '0.5rem',
            }}
          >
            {number > 0 && <StyledNumber>{number}</StyledNumber>}

            <Text as="subhead1">
              {!item.scheduleName || item.scheduleName === item.scheduleAddress
                ? `Event #${number}`
                : item.scheduleName}
            </Text>
          </StyledFlex>

          {!!item.scheduleDay ? (
            <Text
              as="body2"
              dangerouslySetInnerHTML={{
                __html: [
                  `${format(
                    item.scheduleStart,
                    config.dateFormats.weekDayMonthDay
                  )}, ${getTimeDisplay(
                    item.scheduleStart,
                    false
                  ).toLowerCase()} - ${getTimeDisplay(
                    item.scheduleEnd,
                    false
                  ).toLowerCase()}`,
                  userLocation &&
                    item.scheduleLocation &&
                    `${convertDistance(
                      getPreciseDistance(
                        userLocation,
                        item.scheduleLocation.coordinates
                      ),
                      'mi'
                    ).toFixed(1)} mi.`,
                ]
                  .filter(Boolean)
                  .join('<br />'),
              }}
            >
              {null}
            </Text>
          ) : (
            <Text as="body2">{strings.labels.noEvents}</Text>
          )}
        </div>

        <StyledFlex
          css={{
            alignItems: 'flex-start',
            flexWrap: 'nowrap',
            gap: '1rem',
          }}
        >
          <StyledLocation>
            <Text as="body2">{item.vendorName}</Text>
            <Text
              as="caption"
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: 216,
              }}
            >
              {item.vendorProductTypes?.map((type) => type.name).join(', ')}
            </Text>
          </StyledLocation>

          {!!item.vendorImage && (
            <StyledVendorImage
              css={{
                backgroundImage: `url(${item.vendorImage})`,
              }}
            />
          )}
        </StyledFlex>
      </div>
    </StyledCardContainer>
  );
}
