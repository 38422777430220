import { styled } from '@goodfynd/react-web.theme';
import Button from '@mui/material/Button';

import { StyledSearchTextField } from '../../context/search-context/SearchInputs/styles';

export const StyledLocationButton = styled(Button, {
  '&.MuiButton-root': {
    width: '100%',
    display: 'flex',
    justifyContent: 'start',
    columnGap: 16,
  },
});

export const StyledTextField = styled(StyledSearchTextField, {
  '@sm': {
    width: '100%',
  },
  '@lg': {
    width: '100%',
  },
  variants: {
    page: {
      default: {
        width: '100%',
      },
      home: {
        width: '100%',
      },
      search: {
        '&.MuiTextField-root .MuiOutlinedInput-root': {
          paddingBottom: 0,
          paddingTop: 0,
          '.MuiAutocomplete-input': {
            padding: '12px 16px',
          },
        },
        '@md': {
          width: 285,
        },
        '@lg': {
          width: 535,
        },
      },
    },
  },
});
