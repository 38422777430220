import { styled } from '@goodfynd/react-web.theme';

export const StyledCardContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
  padding: '0 1rem 1rem',
  width: '100%',
  '> .content': {
    padding: '1rem 0 0',
  },
});

export const StyledLocation = styled('div', {
  marginTop: '0.75rem',
});

export const StyledNumber = styled('span', {
  alignItems: 'center',
  backgroundColor: 'var(--BackgroundBrand)',
  borderRadius: '50%',
  color: 'var(--TextIconInverse)',
  display: 'flex',
  fontSize: '0.75rem',
  fontWeight: 800,
  height: '1.5rem',
  justifyContent: 'center',
  lineHeight: '1.125rem',
  minWidth: '1.5rem',
  width: '1.5rem',
});

export const StyledVendorImage = styled('div', {
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  borderRadius: '0.25rem',
  minHeight: '4rem',
  minWidth: '4rem',
});
