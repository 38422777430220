import React from 'react';
import { ButtonLink, Link, Text } from '@goodfynd/react-web.lib.ui';
import { StyledFlex } from '@goodfynd/react-web.styles';
import { format, getTimeDisplay } from '@goodfynd/react-web.utils.date-util';
import { convertDistance, getPreciseDistance } from 'geolib';

import config from '../../../config';
import strings from '../../../config/strings';
import { local as storageLocal } from '../../../utils/storage-util';

import {
  StyledCardContainer,
  StyledLocation,
  StyledNumber,
  StyledVendorImage,
} from './styles';
import type { ScheduleMapCardProps } from './types';
export function ScheduleMapCard({ item, number = 0 }: ScheduleMapCardProps) {
  const userLocation = storageLocal.get<ItemLocation>(
    config.storageKeys.userLocation
  );

  return (
    <StyledCardContainer>
      <div className="content">
        <StyledFlex
          css={{
            alignItems: 'flex-start',
            flexWrap: 'nowrap',
            gap: '1rem',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <StyledFlex
              css={{
                alignItems: 'flex-start',
                flexWrap: 'nowrap',
                gap: '0.5rem',
              }}
            >
              {number > 0 && <StyledNumber>{number}</StyledNumber>}

              <Text as="subhead1">
                {!item.scheduleName ||
                item.scheduleName === item.scheduleAddress
                  ? `Event #${number}`
                  : item.scheduleName}
              </Text>
            </StyledFlex>

            {!!item.scheduleAddress && (
              <Link
                href={`https://www.google.com/maps/dir/?api=1&destination=${item.scheduleLocation.coordinates.latitude},${item.scheduleLocation.coordinates.longitude}`}
                style={{ fontSize: '0.875rem', lineHeight: '1.5rem' }}
                target="_blank"
              >
                {item.scheduleAddress}
              </Link>
            )}

            {!!item.scheduleDay ? (
              <Text as="body2">
                {[
                  format(
                    item.scheduleStart,
                    config.dateFormats.weekDayMonthDay
                  ),
                  `${getTimeDisplay(
                    item.scheduleStart,
                    false
                  ).toLowerCase()} - ${getTimeDisplay(
                    item.scheduleEnd,
                    false
                  ).toLowerCase()}`,
                  userLocation &&
                    item.scheduleLocation &&
                    `${convertDistance(
                      getPreciseDistance(
                        userLocation,
                        item.scheduleLocation.coordinates
                      ),
                      'mi'
                    ).toFixed(1)} mi.`,
                ]
                  .filter(Boolean)
                  .join(config.unicode.centerDotPadded)}
              </Text>
            ) : (
              <Text as="body2">{strings.labels.noEvents}</Text>
            )}
          </div>

          {!!item.vendorImage && (
            <StyledVendorImage
              css={{
                backgroundImage: `url(${item.vendorImage})`,
              }}
            />
          )}
        </StyledFlex>

        <StyledLocation>
          <Text as="body2">{item.vendorName}</Text>
          <Text as="caption">
            {item.vendorProductTypes?.map((type) => type.name).join(', ')}
          </Text>
        </StyledLocation>
      </div>

      <ButtonLink
        align="left"
        href={`${config.env.APP_HOST}/schedules/${item.id}`}
        rank="secondary"
        size="small"
        target="_blank"
        style={{
          marginTop: '0.75rem',
        }}
      >
        Order now
      </ButtonLink>
    </StyledCardContainer>
  );
}
